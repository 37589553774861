<template>
  <div class="aiz-user-panel">

    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('dui-hua') }}</h1>
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('dui-hua') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0" :class="dataList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
          <tr>
            <th>{{ $t('chuang-jian-ri-qi') }}</th>
            <th>{{ $t('name') }}</th>
            <th >{{ $t('last-replay') }}</th>
            <th >{{ $t('zhu-ti-1') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in dataList" :key="i">
            <td>{{ item.CreatedAt }}</td>
            <td>{{ item.User.FullName }}</td>
            <td v-if="checkReplay(item) === 2" style="color: red">{{ $t('Wait for reply') }}</td>
            <td v-else>{{ $t('replied') }}</td>
            <td>
              <a  class="btn btn-styled btn-link py-1 px-0 icon-anim text-underline--none" @click="toDetail(item)">
                {{ item.Subject }}<i class="la la-angle-right text-sm"></i>
              </a>
            </td>
          </tr>

          <tr class="footable-empty" v-if="dataList.length == 0"><td colspan="6">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination mt-4" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>


    <Popup v-model="showPopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('chuang-jian-gong-dan-0') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zhu-ti-1') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <input type="text"  class="form-control mb-3"  :placeholder="$t('zhu-ti-1')" v-model="form.subject">
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('xiang-xi-shuo-ming') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <textarea type="text" class="form-control mb-3" rows="3" v-model="form.desc" :placeholder="$t('shu-ru-ni-de-miao-shu')" ></textarea>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zhao-pian') }} </label>
                  </div>
                  <div class="col-md-9">
                    <div class="mb-3">
                      <div class="input-group" data-toggle="aizuploader" data-type="image" data-multiple="true">
                        <div class="input-group-prepend">
                          <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                        </div>
                        <div class="form-control file-amount" @click="addFile(2)">{{ $t('xuan-ze-wen-jian') }}</div>
                      </div>
                      <previewBar :fileList="fileList" @remove="removeFile"></previewBar>
                    </div>
                  </div>
                </div>
                <div class="mt-4 text-right">
                  <button type="button" class="btn btn-secondary" @click="cancel">{{ $t('qu-xiao') }}</button>
                  <button type="button" class="btn btn-primary ml-2" @click="submit">{{ $t('que-ding') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
import previewBar from '@/components/preview'
import { addWorkOrder, workOrderList, fileUpload } from '@/api/user'
import {dialogueList} from "@/api";
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      form: {
        subject: '',
        desc: '',
      },
      fileList: [],
      showPopup: false,
      userMoney: 10,
      dataList: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      dialogueList(form).then(res => {
        this.dataList = res.data.Items
        this.page.total = res.data.Pagination.totalPage
      })
    },
    checkReplay(item){
      if(item.DialogueReply.length == 0) {
        // 未回复
        return 2
      }
      let index = item.DialogueReply.length - 1
      if(item.MerchantID !== item.DialogueReply[index].UId) {
        return 2
      }
      return 1
    },
    changePage(page) {
      this.page.page = page
      this.init()
    },
    cancel() {
      this.showPopup = false
    },
    add() {
      this.showPopup = true
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    toDetail(data) {
      sessionStorage.setItem('dialogueList', JSON.stringify(data))
      this.$router.push({
        name: 'sellerConversationsDetail',
        query: {
          id: data.ID
        }
      })
    },
    // toDetail(data) {
    //   sessionStorage.setItem('ticketData', JSON.stringify(data))
    //   this.$router.push({
    //     name: 'ticketDetail',
    //     query: {
    //       id: data.ID
    //     }
    //   })
    // },
    removeFile(i) {
      this.fileList.splice(i, 1)
    },
    submit() {
      if (!this.form.subject) {
        this.$toast.fail(this.$t('qing-shu-ru-zhu-ti'))
        return
      }
      if (!this.form.desc) {
        this.$toast.fail(this.$t('qing-shu-ru-miao-shu'))
        return
      }
      let form = new FormData()
      form.append('Subject', this.form.subject)
      form.append('Content', this.form.desc)
      form.append('Images', this.fileList.map(v => {
        return v.id
      }))
      addWorkOrder(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('chuang-jian-cheng-gong'))
          this.page.page = 1
          this.showPopup = false
          this.form.subject = ''
          this.form.desc = ''
          this.fileList = []
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>